@charset 'UTF-8';

// form title wrapper
.register-title-wrap {
	padding:0 15px;
	@include screen(custom, max, $max-sm) {
		padding:0 9px;
	}
}
.register-title {
	position:relative;
	width:100%;
	max-width:1284px;
	margin:0 auto;
	@include font-family($font-regular);
	text-align:center;
	background-color:transparent !important;
	&.line {
		border-bottom:2px solid $line-darkgray;
		.txt-area {
			padding: 17px 0 25px;
			@include screen(custom, max, $max-sm) {
				padding-bottom:19px;
			}
		}
		.desc {
			padding-top:10px !important;
			@include screen(custom, max, $max-sm) {
				padding-top:7px !important;
			}
		}
	}
	.txt-area {
		padding:17px 0 25px;
		.txt-wrap {
			.title {
				font-size:32px;
				line-height:1.2;
				max-width:860px;
				margin:0 auto;
				padding:0;
				@include font-family($font-semibold);
				color:$color-black;
			}
			.desc {
				max-width:800px;
				margin:6px auto 0;
				font-size:16px;
				line-height:1.2;
				color:$color-nightrider;
				.count {
					color:$color-carmine;
					@include font-family($font-bold);
				}
				&:empty {padding:0;}
				strong {
					@include font-family($font-bold);
				}
			}
		}
		@include screen(custom, max, $max-sm) {
			padding:33px 0 40px;
			.txt-wrap {
				.title {
					font-size:18px;
				}
				.desc {
					padding-top:5px;
					font-size:14px;
				}
			}
		}
	}
}
// form content wrapper
.wrapper {
	// resister title
	.start-guide {
		@extend %clearfix;
		margin-top:0;
		margin-bottom: 5px;
		text-align: right;
		[dir="rtl"] & {
			text-align:left;
		}
		@include screen(custom, max, $max-sm) {
			margin-top:-13px;
			margin-bottom: 8px;
		}
		.require-area {
			display: block;
			text-align:inherit;
			font-size: 16px;
			line-height: 24px;
			@include screen(custom, max, $max-sm) {
				font-size: 14px;
				line-height: 20px;
			}
		}
	}
	// reset
	em {font-style: normal;}
	p, input {width: 100%;}
	p, label {margin:0;}

	max-width: 1284px + 30px;
	margin:auto;
	padding: 0 15px 70px;
	@include placeholder($color-gray);
	@include screen(custom, max, $max-sm) {
		padding: 0 9px 40px;
	}
	.form-section {
		background: $bg-whitesmoke;
		border: 1px solid $line-gray;
		padding: 60px 200px 60px;
		[dir="rtl"] & {text-align: right;}
		@include screen(custom, max, $max-lg) {
			padding-left: 117px;
			padding-right: 117px;
		}
		@include screen(custom, max, $max-md) {
			padding-left: 57px;
			padding-right: 57px;
		}
		@include screen(custom, max, $max-sm) {
			padding: 21px 19px 29px;
		}
		&.bg-white{
			background:$bg-white !important;
		}
		&.bg-dark{
			background:$bg-nightrider !important;
		}
		&.bg-lightgray {
			background:$bg-whitesmoke !important;
		}
		&.bg-black{
			background:#000 !important;
		}
		&.font-white{
			.box-title{
				color:#fff !important;
			}
			.require{
				color: #fff703 !important;
			}
		}
		&.font-black{
			.box-title{
				color:#000 !important;
			}
		}

		& + * {margin-top:20px;}
		&.white-box {
			background-color: $bg-white;
			padding-top:31px;
			padding-bottom:32px;
			@include screen(custom, max, $max-sm) {
				padding-top:18px;
				padding-bottom:19px;
			}
			.section-title {
				font-size:16px;
				font-weight:600;
				color: $color-black;
				@include font-family($font-semibold);
				@include screen(custom, max, $max-sm) {
					font-size: 16px;
					line-height:24px;
				}
			}
			.description {
				font-size:16px;
				line-height:24px;
				color: $color-dimgray;
				margin-top:6px;
				@include screen(custom, max, $max-sm) {
					font-size: 14px;
					line-height:20px;
					margin-top:2px;
				}
			}
		}
		// common layout
		.inner {@extend %clearfix;}
		.border-box {border-top: 1px solid $line-gray;}
		.error-msg {
			margin: 4px 0 0 10px;
			[dir="rtl"] & {margin: 4px 10px 0 0;}
		}
		.list {
			@extend %clearfix;
			& ~ .list {
				margin-top:24px;
			}
			&.list-toggle {display: none;}
			&:not(:last-of-type),
			&:not(:last-child) {
				.border-box {margin-top: 30px - 24px;}
			}
			&:nth-last-of-type(2),
			&:nth-last-child(2) {
				.border-box {
					padding-bottom:30px;
					@include screen(custom, max, $max-sm) {
						padding-bottom:17px;
					}
				}
			}
			&.textarea-lay  {
				margin-top: 9px;
				@include screen(custom, min, $min-sm) {
					margin-bottom: 4px;
				}
			}
			&.update-check {
				margin-top:26px;
				border-bottom:1px solid $line-lightgray;
				padding-bottom:30px;
				@include screen(custom, max, $max-sm) {
					padding-bottom:15px;
				}
				& + .list {margin-bottom:22px;}
			}
			@include screen(custom, max, $max-sm) { 
				&:nth-child(n) {margin-top: 0;}
				&:first-child .write-type:first-child {
					margin-top:0;
				}
			}
			.box-title {
				display: block;
				color: $color-nightrider;
				font-size: 16px;
				line-height: 24px;
				// margin-bottom:0;
				@include font-family($font-semibold);
				@include screen(custom, max, $max-sm) {
					font-size: 14px;
					line-height: 20px;
				}
			}
			.write-type {
				position:relative;
				width: calc(50% - 12px);
				float: left;
				&.ru{
					font-size: 0;
					.box-title {
						display: block;
					}
					.field-block {
						input{
							width: calc(100% - 104px);
							display: inline-block;
						}
						input:first-of-type{
							width: 94px;
							margin-right: 10px;
						}
					}
				}
				&.full {
					width: 100%;
					float: none;
				}
				&:nth-child(odd) {
					margin-right:24px;
				}
				@include screen(custom, max, $max-sm) { 
					width: 100%;
					margin-top: 14px;
					&:nth-child(n) {
						float: none;
					}
					&:nth-child(odd) {
						margin-right:0;
					}
				}
				input[disabled]::-ms-input-placeholder { color: $color-nightrider; }
				input[disabled]::-webkit-input-placeholder { color: $color-nightrider; } 
				input[disabled]::-moz-placeholder { color: $color-nightrider; }
			}
			.input-info-text {
				font-size:14px;
				line-height:20px;
				color:$color-dimgray;
				margin:4px 0 1px 10px;
				@include screen(custom, max, $max-sm) {
					br {display:none;}
					padding-bottom: 5px;
					margin-bottom: 0;
				}
			}
		}
		// input, select, check field
		.field-block {
			margin-top: 7px;
			@include screen(custom, max, $max-sm) {
				margin-top: 3px;
			}
			.note {
				display: block;
				margin: 0 0 -10px;
				font-size: 14px;
				line-height: 20px;
				color: $color-dimgray;
				padding: 4px 5px 5px 11px;
				@include screen(custom, max, $max-sm) {
					margin:0;
					padding-right:0px;
				}
				[dir="rtl"] & {
					padding: 4px 11px 5px 5px;
					@include screen(custom, max, $max-sm) {
						padding-left:0px;
					}
				}
			}
		}
	}
	.field-btn-area {
		text-align: center;
		margin-top: 20px;
		@include screen(custom, max, $max-sm) {
			margin-top: 20px;
		}
		.btn {
			width: 249px;
			margin:0 3px;
			@include screen(custom, max, $max-sm) {
				width: 100%;
				margin:0;
				&:not(:first-child) {
					margin-top: 10px;
				}
			}
		}
	}	
}

