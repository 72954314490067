@charset 'UTF-8';
@import "./setting/variables";
@import './customer/register-form'; //LGERU-92 추가 컴포넌트
$max-xxs : 444;
.subscribe-container {
    max-width: 1284px + 30px;
    margin: auto;
    padding: 0 15px 50px;
    @include screen(custom, max, $max-sm) {
        padding: 0 9px 40px;
    }
    .inner {
        background-color: $bg-whitesmoke;
        border: 1px solid $line-gray;
        padding: 40px 0 41px;
        @include screen(custom, max, $max-sm) {
            padding: 21px 19px 29px;
        }
        .header-group {
            position: relative;
            width: 100%;
            max-width: 848px;
            margin: 0 auto 32px;
            text-align: center;
            word-break: break-word;
            @include screen(custom, max, $max-sm) {
                margin-bottom: 22px;
            }
            .title {
                font-size: 32px;
                color: $color-black;
                @include font-family($font-semibold);

                @include screen(custom, max, $max-sm) {
                    font-size: 24px;
                    line-height: 28px;
                }
            }
            .desc {
                margin: 0 auto;
                padding-top: 12px;
                font-size: 16px;
                color: $color-dimgray;

                @include screen(custom, max, $max-sm) {
                    padding-top: 8px;
                    font-size: 14px;
                    line-height: 25px;
                }
            }
            // 20200401 START 이지원 tooltip 관련 불필요 속성 제거
            .tooltip-link {
                background: transparent;
                border: none;
                text-decoration: underline;
                word-break: break-all;
                cursor: pointer;
                &.title-link{
                    font-weight: 600;
                }
                &:not(.title-link) {
                    padding: 0;
                    color: #a50034;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                }
            }
            .desc a {
                color: #a50034;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                text-decoration: underline;
                cursor: pointer;
            }
            .tooltip-area {
                display: none;
                position: absolute;
                width:370px;
                @include screen(custom, max, $max-sm) {
                    width:275px;
                }
            }
            // 20200401 END
        }
        .form-section {
            [dir="rtl"] & {
                text-align: right;
            }
            position: relative;
            max-width: 1284px - (150px * 2);
            margin: auto;
            @include screen(custom, max, $max-lg) {
                padding-left: 117px;
                padding-right: 117px;
            }
            @include screen(custom, max, $max-md) {
                padding-left: 57px;
                padding-right: 57px;
            }
            @include screen(custom, max, $max-sm) {
                padding: 0;
            }
            .subscribe-inner {
                max-width: 848px;
                margin: 0 auto;
                @extend %clearfix;
                // subscribe section
                .input-row {
                    display: inline-block;
                    width: 100%;
                    @include screen(custom, max, $max-sm) {
                        display: block;
                    }
                    .field-block {
                        display: block;
                        float: left;
                        width: calc(100% - 171px);
                        [dir="rtl"] & {
                            float: right;
                        }
                        @include screen(custom, max, $max-sm) {
                            display: block;
                            float: none;
                            width: 100%;
                        }
                        label.hidden {
                            display: none;
                        }
                        input[type="email"] {
                            width: 100%;
                            height: 44px;
                            padding: 0 10px;
                            font-size: 16px;
                            line-height: 24px;
                            border: 1px solid #6b6b6b;
                            background: #fff;
                            color: #333;
                            font-style: normal;
                            font-weight: 400;
                        }
                    }
                    .btn {
                        float: left;
                        margin-top: 2px;
                        margin-left: 20px;
                        height: 40px;
                        [dir="rtl"] & {
                            float: right;
                            margin-left: 0;
                            margin-right: 20px;
                        }
                        @include screen(custom, max, $max-sm) {
                            display: block;
                            float: none;
                            width: 100%;
                            margin-top: 20px;
                            margin-left: 0;
                            [dir="rtl"] & {
                                margin-right: 0;
                            }
                        }
                    }
                }
                .field-block.ajax-error {
                    margin: 0;
                }
                .warning-icon {
                    text-align: center;
                    margin-top: 16px;
                }
            }
        }
    }
}

// ajax-success || fail
.success-container,
.post-success-container,
.fail-container {
    display: none;
    max-width: 1314px;
    margin: -9px auto 0;
    padding: 0 15px 20px;
    color: $color-nightrider; // This is to use to icon color.
    box-sizing: border-box;
    @include screen(custom, max, $max-sm) {
        margin: 0;
        padding: 0 9px;
    }
    .completion-box {
        text-align: center;
        padding: 49px 0 45px;
        margin: 0 0 45px;
        border: 1px solid $line-gray;
        background-color: $bg-whitesmoke;
        @include screen(custom, max, $max-sm) {
            padding: 29px 0 35px;
        }
        .icon {
            display: inline-block;
            width: 64px;
            height: 64px;
            line-height: 64px - 2px;
        }
        .completion-title {
            display: block;
            margin-top: 14px;
            font-size: 24px;
            line-height: 34px;
            color: $color-black;
            @include font-family($font-semibold);

            @include screen(custom, max, $max-sm) {
                @include font-family($font-semibold);
                margin: 12px 30px 0;
                font-size: 20px;
                line-height: 26px;
            }
        }
    }
}
.fail-container {
    .completion-title {
        margin-top: 16px;
    }
}
